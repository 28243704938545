import { localeEn, localeJa } from "../locale";

export const useLocale = () => {
  const lang = window.navigator.language;
  if (lang.slice(0, 2) === "ja") {
    return localeJa;
  }

  return localeEn;
};
