import { useCallback, ChangeEvent, useState, useEffect } from "react";
import { useClient } from "../../../hooks/ClientHook";

type Status =
  | "PENDING"
  | "SENDING"
  | "SENDING_COMPLETE"
  | "RECEIVING_PENDING"
  | "RECEIVING"
  | "ERROR";

export const useSharePageState = (sessionID: string) => {
  const { client } = useClient();
  const [status, setStatus] = useState<Status>("PENDING");
  const [file, setFile] = useState<File | null>(null);
  const [downloadURL, setDownloadURL] = useState("");

  /**
   * 選択したファイルを送る
   */
  const sendFile = useCallback(async () => {
    if (!file || !client) {
      return;
    }

    // まずはアップロードURLを取得
    let uploadURL: string = "";
    try {
      const resp = await client.requestUploadURL(sessionID, file.name);
      if (!resp.payload.ok) {
        console.error(resp);
        setStatus("ERROR");
        return;
      }
      uploadURL = resp.payload.url;
    } catch (e) {
      console.error(e);
      setStatus("ERROR");
      return;
    }

    // 実際にファイルをアップロードする
    try {
      const resp2 = await fetch(uploadURL, {
        method: "PUT",
        body: file,
        mode: "cors",
      });
      if (!resp2.ok) {
        console.error(await resp2.text());
        setStatus("ERROR");
        return;
      }
    } catch (e) {
      console.error(e);
      setStatus("ERROR");
      return;
    }

    // アップロード完了通知を送る
    const resp = await client.completeUplaod(sessionID);
    if (!resp.payload.ok) {
      console.error(resp);
      setStatus("ERROR");
      return;
    }

    setStatus("SENDING_COMPLETE");
  }, [file, client, sessionID]);

  /**
   * 相手のアップロード開始イベントを待つ
   */
  const waitPartnerBeganUpload = useCallback(async () => {
    if (!client) {
      return;
    }
    await client.waitPartnerBeganUpload();
    setStatus("RECEIVING_PENDING");
  }, [client]);

  /**
   * 相手のアップロード完了を待つ
   */
  const waitUploadComplete = useCallback(async () => {
    if (!client) {
      return;
    }
    const event = await client.waitCompleteUpload();
    setDownloadURL(event.payload.url);
    setStatus("RECEIVING");
  }, [client]);

  /**
   * ファイル選択のコールバック
   */
  const onSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      console.error("file not selected");
      return;
    }
    const file = files.item(0);
    // アップロード開始
    setFile(file);
    setStatus("SENDING");
  }, []);

  /**
   * ファイルをダウンロードする
   */
  const downloadFile = useCallback(async () => {
    if (!downloadFile) {
      console.error("downloadFile not defined.");
      return;
    }
    const resp = await fetch(downloadURL, {
      mode: "cors",
    });
    const blob = await resp.blob();
    const anchor = document.createElement("a");
    const objectURL = URL.createObjectURL(blob);
    anchor.download = "";
    anchor.href = objectURL;
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(() => {
      // WebKitBlobResource error 1 を回避するため
      URL.revokeObjectURL(objectURL);
      document.body.appendChild(anchor);
    }, 10000);
  }, [downloadURL]);

  useEffect(() => {
    switch (status) {
      case "PENDING":
        void waitPartnerBeganUpload();
        return;
      case "SENDING":
        void sendFile();
        return;
      case "RECEIVING_PENDING":
        void waitUploadComplete();
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return {
    status,
    onSelectFile,
    downloadFile,
  };
};
