import React from "react";
import Typography from "@material-ui/core/Typography";
import { useLocale } from "../hooks/LocaleHook";

export class ErrorBoundary extends React.Component<
  any,
  { error: Error | null }
> {
  constructor(props: unknown) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  render(): React.ReactNode {
    const l = useLocale();
    if (this.state.error) {
      return (
        <>
          <Typography variant="body1" color="error">
            {this.state.error.message || l.errors.somethingWrong}
          </Typography>
          <a href="/">
            <Typography variant="body1">{l.links.back}</Typography>
          </a>
        </>
      );
    }

    return this.props.children;
  }
}
