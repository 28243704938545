import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import { useLocale } from "../../hooks/LocaleHook";

export const NotFoundPage: FC = () => {
  const l = useLocale();
  return (
    <>
      <Typography color="textSecondary" variant="body1">
        {l.errors.notFound}
      </Typography>
    </>
  );
};
