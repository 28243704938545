import React, { FC, useEffect, useState } from "react";
import { useClient } from "../../hooks/ClientHook";
import { useMountEffect } from "../../hooks/internals/MountEffectHook";
import { useJoinSessionRequest } from "../../hooks/RequestHook";
import { Loader } from "../../components/Loader";
import { useParams } from "react-router-dom";
import { SharePage } from "../share/SharePage";
import { useLocale } from "../../hooks/LocaleHook";

export const GuestPage: FC<{}> = () => {
  const l = useLocale();
  const { error, ready, expired } = useClient();

  if (error) {
    // 雑に投げても Boundary がキャッチしてくれるやろ
    throw error;
  }
  if (!ready) {
    return <Loader loading />;
  }
  if (expired) {
    throw new Error(l.errors.expired);
  }
  return <GuestPageRequesting />;
};

const GuestPageRequesting: FC<{}> = () => {
  const l = useLocale();
  const { sessionID } = useParams<{ sessionID: string }>();
  const { error, trigger, result: resp } = useJoinSessionRequest(sessionID);
  const [joined, setJoined] = useState(false);
  const ok = Boolean(resp?.payload.ok);

  useMountEffect(() => {
    trigger();
  });
  useEffect(() => {
    if (ok) {
      setJoined(true);
    }
  }, [ok, setJoined]);

  if (error) {
    throw error;
  }
  if (resp && !ok) {
    throw new Error(l.errors.invalidSession);
  }
  if (!resp) {
    return <Loader loading />;
  }
  if (joined) {
    // ジョインしたら共有ページを表示する
    return <SharePage sessionID={sessionID} />;
  }
  // 特に表示するものはない
  return <></>;
};
