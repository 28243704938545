import React, { FC } from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useLocale } from "../hooks/LocaleHook";

const useStyles = makeStyles((theme) => ({
  self: {
    marginTop: "64px",
    marginBottom: "32px",
  },
  devider: {
    marginBottom: "16px",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

export const Footer: FC = () => {
  const l = useLocale();
  const classes = useStyles();
  return (
    <div className={classes.self}>
      <Divider className={classes.devider} />
      <Typography variant="body2" color="textSecondary">
        {l.labels.author}{" "}
        <a
          className={classes.link}
          href="https://github.com/FujiHaruka"
          target="_blank"
          rel="noopener noreferrer"
        >
          {l.links.author}
        </a>
      </Typography>
    </div>
  );
};
