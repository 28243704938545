import "./Loader.css";
import React, { FC } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  svg: {
    fontSize: "4rem",
    animation: "rotate-animation 3s linear infinite",
  },
  shuriken: {
    fill: theme.palette.grey[900],
  },
}));

const Shuriken = () => {
  const classes = useStyles();
  return (
    <g>
      <path
        className={classes.shuriken}
        d={`M320.004,192.02L313.6,0C240.185,30.712,192.558,122.902,192.02,191.996L0,198.399
    c30.712,73.416,122.902,121.042,191.996,121.58L198.4,512c73.415-30.712,121.042-122.902,121.58-191.996L512,313.6
    C481.288,240.185,389.098,192.55,320.004,192.02z M257.021,284.512c-15.744,0-28.504-12.768-28.504-28.512
    s12.76-28.511,28.504-28.511c15.752,0,28.511,12.767,28.511,28.511S272.773,284.512,257.021,284.512z`}
      ></path>
    </g>
  );
};

export const Loader: FC<{ loading: boolean }> = ({ loading }) => {
  const classes = useStyles();
  if (!loading) {
    return null;
  }
  return (
    <div className={classes.loader}>
      <SvgIcon className={classes.svg} color="primary" viewBox="0 0 512 512">
        <Shuriken />
      </SvgIcon>
    </div>
  );
};
