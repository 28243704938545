import React, { FC, useEffect, useState } from "react";
import { useClient } from "../../hooks/ClientHook";
import { useMountEffect } from "../../hooks/internals/MountEffectHook";
import {
  useRequestSessionRequest,
  useWaitJoinedEvent,
} from "../../hooks/RequestHook";
import { makeStyles } from "@material-ui/core/styles";
import { Loader } from "../../components/Loader";
import { QrCode } from "./components/QrCode";
import { Introduction } from "./components/Introduction";
import { SharePage } from "../share/SharePage";
import { Footer } from "../../layouts/Footer";
import { useLocale } from "../../hooks/LocaleHook";

const useStyles = makeStyles((theme) => ({
  qr: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  introduction: {
    marginTop: "96px",
    width: "100%",
  },
}));

export const HostPage: FC<{}> = () => {
  const { error, ready, expired } = useClient();

  if (error) {
    // 雑に投げても Boundary がキャッチしてくれるやろ
    throw error;
  }
  if (!ready) {
    return <Loader loading />;
  }
  return <HostPageInnter expired={expired} />;
};

const HostPageInnter: FC<{ expired: boolean }> = ({ expired }) => {
  const l = useLocale();
  const classes = useStyles();
  const [joined, setJoined] = useState(false);
  const { result: joinedEvent, trigger: trigger1 } = useWaitJoinedEvent();
  const {
    error,
    trigger: trigger2,
    result: sessionResp,
  } = useRequestSessionRequest();
  const sessionID = sessionResp?.payload.sessionID;
  const sessionUrl = new URL(`#/sess/${sessionID}`, window.location.origin)
    .href;

  useMountEffect(() => {
    trigger1();
    trigger2();
  });
  useEffect(() => {
    // 相手がジョインしてきたらこっちも画面遷移する
    if (joinedEvent) {
      setJoined(true);
    }
  }, [joinedEvent, setJoined]);

  if (error) {
    throw error;
  }
  if (sessionResp && !sessionResp.payload.ok) {
    throw new Error(l.errors.somethingWrong);
  }
  if (joined && sessionID) {
    // ジョインしたら共有ページを表示する
    return <SharePage sessionID={sessionID} />;
  }
  return (
    <>
      <div className={classes.qr}>
        {sessionResp ? (
          <QrCode url={sessionUrl} expired={expired} />
        ) : (
          <Loader loading />
        )}
      </div>
      <div className={classes.introduction}>
        <Introduction />
      </div>
      <Footer />
    </>
  );
};
