import React, { FC } from "react";
import { useLocale } from "../../../hooks/LocaleHook";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: "16px",
  },
}));

export const Introduction: FC<{}> = () => {
  const l = useLocale();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4">{l.titles.introduction}</Typography>
      <img
        className={classes.image}
        width="100%"
        src="/images/share.png"
        alt={l.alts.share}
      />
      <Typography variant="body1">
        <p>{l.descriptions.howToShare}</p>
        <ol>
          <li>{l.descriptions.step1}</li>
          <li>
            {l.descriptions.step2_1}
            <br />
            {l.descriptions.step2_2}
          </li>
          <li>{l.descriptions.step3}</li>
        </ol>
      </Typography>
    </>
  );
};
