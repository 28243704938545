import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useLocale } from "../hooks/LocaleHook";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  title: {
    display: "inline-block",
  },
  titleLink: {
    textDecoration: "none",
    display: "inline-block",
    color: "inherit",
  },
  logo: {
    height: "7rem",
    marginBottom: "16px",
    verticalAlign: "bottom",
    maxWidth: "100%",
    objectFit: "contain",
  },
  main: {
    marginTop: "64px",
    marginBottom: "64px",
  },
}));

export const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const l = useLocale();
  return (
    <Container maxWidth="xs">
      <div className={classes.header}>
        <a className={classes.titleLink} href="/">
          <img
            className={classes.logo}
            src="/images/title-logo.png"
            alt={l.titles.app}
          />
        </a>
        <Typography variant="subtitle1" color="textSecondary">
          {l.leads.app}
        </Typography>
      </div>
      <div className={classes.main}>{children}</div>
    </Container>
  );
};
