export const localeEn = {
  alts: {
    qr: "Share your file via this QR coe",
    share: "Share files between PC and mobile.",
  },
  buttons: {
    send: "Send File",
    receive: "Receive",
  },
  descriptions: {
    howToShare: "Easy to use. Just 3 steps.",
    step1: "Scan the QR code with a mobile",
    step2_1: "Send a file.",
    step2_2: "From the PC or the mobile",
    step3: "Receive the file",
  },
  titles: {
    app: "Share Ninja",
    introduction: "How to share my file?",
  },
  labels: {
    author: "Creator",
  },
  leads: {
    app: "Share your files between PC and mobile.",
    qr: "Read this QR by a mobile to start sharing a file!",
  },
  errors: {
    somethingWrong:
      "Oops, something went wrong. We are sorry to trouble you, but please try again from the start.",
    invalidSession: "Invalid session. Your partner may have left.",
    wsConnection: "WebSocket connection error.",
    expired: "Connection expired. Please try again.",
    notFound: "404 NOT FOUND",
  },
  messages: {
    again: "Share again",
    startDownload: "Download will starts soon.",
    thankYou: "Thank you for using Share Ninja.",
    uploadCompleted: "File has been sent. Ready to receive.",
  },
  links: {
    back: "Back to Main Page",
    author: "Fuji Haruka",
  },
};

export const localeJa: typeof localeEn = {
  alts: {
    qr: "このQRコードを読み込んでファイルを共有します。",
    share:
      "Share Ninja はPC⇔モバイル間で貴方の大切なファイルを簡便に共有できるサービスです。",
  },
  buttons: {
    send: "ファイルを送る",
    receive: "受け取る",
  },
  descriptions: {
    howToShare: "使いかたは簡単。たったこれだけです。",
    step1: "スマホで QR コードを読み込みます。",
    step2_1: "ファイルを送ります。",
    step2_2: "パソコンからでもスマホからでも。",
    step3: "ファイルを受け取ります。",
  },
  titles: {
    app: "Share Ninja",
    introduction: "使いかた",
  },
  labels: {
    author: "製作者",
  },
  leads: {
    app: "PC⇔スマホ間でファイルを送れるでござる。",
    qr: "QRコードをスマホで読み込むと共有を開始します。",
  },
  errors: {
    somethingWrong:
      "おっと！なんらかの問題が発生したでござる。少し時間をおいて、もう一度試してもらえないだろうか？",
    invalidSession:
      "セッションが切断されました。恐らくPC側でシェア忍者が閉じられてしまったのかもしれません。もう一度開き直してもらえますか？",
    wsConnection:
      "ウェブソケットの接続に失敗しました。もし何度もこのメッセージが表示されるようなら、別のネットワークで試してもらえますでしょうか？",
    expired: "接続の有効期限が切れました。もう一度つなぎ直してもらえますか？",
    notFound:
      "404 ページが見つかりません。URLが正しいか確認してもらえますでしょうか？",
  },
  messages: {
    again: "もう一度シェアする",
    startDownload: "すぐにダウンロードが始まります。",
    thankYou:
      "Share Ninja をご利用いただき、ありがとうございました！またいつでも頼ってくださいね。",
    uploadCompleted: "ファイル送信完了。準備が整いました！",
  },
  links: {
    back: "メインページに戻る",
    author: "Fuji Haruka",
  },
};
