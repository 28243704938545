import { useClient } from "./ClientHook";
import { useCallback } from "react";
import { useAsync, AsyncHookValues } from "./internals/AsyncHook";
import {
  SessionResponse,
  JoinResponse,
  JoinEvent,
  RequestUploadURLResponse,
} from "../Message";

// --- Host side

export const useRequestSessionRequest = (): AsyncHookValues<SessionResponse | null> => {
  const { client } = useClient();
  const request = useCallback(() => {
    if (!client) {
      return Promise.resolve(null);
    }
    return client.requestSession();
  }, [client]);
  const values = useAsync(request);
  return values;
};

export const useWaitJoinedEvent = (): AsyncHookValues<JoinEvent | null> => {
  const { client } = useClient();
  const listen = useCallback(() => {
    if (!client) {
      return Promise.resolve(null);
    }
    return client.waitJoined();
  }, [client]);
  const values = useAsync(listen);
  return values;
};

// --- Guest side

export const useJoinSessionRequest = (
  sessionID: string
): AsyncHookValues<JoinResponse | null> => {
  const { client } = useClient();
  const request = useCallback(() => {
    if (!sessionID || !client) {
      return Promise.resolve(null);
    }
    return client.joinSession(sessionID);
  }, [sessionID, client]);
  const values = useAsync(request);
  return values;
};

// --- Both side

export const useRequestUploadURLRequest = (
  sessionID: string,
  filename: string
): AsyncHookValues<RequestUploadURLResponse | null> => {
  const { client } = useClient();
  const request = useCallback(() => {
    if (!sessionID || !client || !filename) {
      return Promise.resolve(null);
    }
    return client.requestUploadURL(sessionID, filename);
  }, [sessionID, filename, client]);
  const values = useAsync(request);
  return values;
};
