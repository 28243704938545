import React, { FC, useState } from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import Typography from "@material-ui/core/Typography";
import { useLocale } from "../../../hooks/LocaleHook";
import { Loader } from "../../../components/Loader";
import { makeStyles } from "@material-ui/core/styles";
import { AgainLink } from "../../../components/AgainLink";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "12px 40px",
    fontSize: "1.5rem",
  },
}));

export const ReceiveButton: FC<{
  downloadFile: () => Promise<void>;
  ready: boolean;
}> = ({ downloadFile, ready }) => {
  const l = useLocale();
  const classes = useStyles();
  const [preparing, setPreparing] = useState(false);
  const [done, setDone] = useState(false);
  const [failed, setFailed] = useState(false);
  const onClick = async () => {
    setPreparing(true);
    try {
      await downloadFile();
      setPreparing(false);
      setDone(true);
    } catch (e) {
      setFailed(false);
    }
  };

  if (failed) {
    return (
      <Typography variant="body1" color="error">
        {l.errors.somethingWrong}
      </Typography>
    );
  }
  if (preparing) {
    return <Loader loading />;
  }
  if (done) {
    // TODO: ダウンロードが終わったらシェアボタンもほしいよね！
    return (
      <>
        <Typography variant="body1" color="textPrimary">
          {l.messages.startDownload}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {l.messages.thankYou}
        </Typography>
        <AgainLink />
      </>
    );
  }
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      disabled={!ready}
      onClick={onClick}
      endIcon={<GetAppIcon />}
    >
      {l.buttons.receive}
    </Button>
  );
};
