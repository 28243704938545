import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale } from "../../hooks/LocaleHook";
import { useSharePageState } from "./hooks/SharePageStateHook";
import { Loader } from "../../components/Loader";
import { useClient } from "../../hooks/ClientHook";
import { ReceiveButton } from "./components/ReceiveButton";
import { SendButton } from "./components/SendButton";
import Typography from "@material-ui/core/Typography";
import { AgainLink } from "../../components/AgainLink";

const useStyles = makeStyles(() => ({
  main: {
    width: "100%",
    textAlign: "center",
  },
}));

export const SharePage: FC<{ sessionID: string }> = ({ sessionID }) => {
  const l = useLocale();
  const { error, ready, expired } = useClient();
  const classes = useStyles();

  if (error) {
    // 雑に投げても Boundary がキャッチしてくれるやろ
    throw error;
  }
  if (!ready) {
    return <Loader loading />;
  }
  if (!sessionID) {
    throw new Error("sessionID is not defined");
  }
  if (expired) {
    throw new Error(l.errors.expired);
  }
  return (
    <div className={classes.main}>
      <SharePageInner sessionID={sessionID} />
    </div>
  );
};

export const SharePageInner: FC<{ sessionID: string }> = ({ sessionID }) => {
  const l = useLocale();
  const { onSelectFile, status, downloadFile } = useSharePageState(sessionID);
  if (status === "ERROR") {
    throw new Error(l.errors.somethingWrong);
  }
  switch (status) {
    // 初期状態
    case "PENDING":
      return (
        <>
          <SendButton onSelectFile={onSelectFile} />
        </>
      );
    // 相手がアップロードを始めた
    case "RECEIVING_PENDING":
    // 受け取りの準備ができた
    // eslint-disable-next-line no-fallthrough
    case "RECEIVING":
      return (
        <>
          <ReceiveButton
            downloadFile={downloadFile}
            ready={status === "RECEIVING"}
          />
        </>
      );
    // アップロード中
    case "SENDING":
      return (
        <>
          <Loader loading />
        </>
      );
    // アップロード完了
    case "SENDING_COMPLETE":
      return (
        <>
          <Typography variant="body1" color="textPrimary">
            {l.messages.uploadCompleted}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {l.messages.thankYou}
          </Typography>
          <AgainLink />
        </>
      );
  }
};
