import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale } from "../hooks/LocaleHook";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  again: {
    display: "block",
    marginTop: "2em",
  },
}));

export const AgainLink: FC = () => {
  const classes = useStyles();
  const l = useLocale();
  return (
    <a href="/" className={classes.again}>
      <Typography variant="body1" color="textPrimary">
        {l.messages.again}
      </Typography>
    </a>
  );
};
