import {
  SessionRequest,
  SessionResponse,
  JoinRequest,
  JoinResponse,
  JoinEvent,
  ActionBase,
  EventBase,
  RequestUploadURLRequest,
  RequestUploadURLResponse,
  PartnerBeganUploadEvent,
  CompleteUploadRequest,
  CompleteUploadResponse,
  CompleteUploadEvent,
} from "./Message";
import { localeEn } from "./locale";

const URL = "wss://8mdzlbbj0f.execute-api.ap-northeast-1.amazonaws.com/Prod";

export class Client {
  static async connect(url = URL): Promise<Client> {
    const ws = new WebSocket(url);
    await new Promise((resolve, reject) => {
      const onOpen = () => {
        console.log("WebSocket connection opened");
        ws.removeEventListener("open", onOpen);
        resolve();
      };
      ws.addEventListener("open", onOpen);
      ws.addEventListener("error", (e) => {
        console.error(e);
        reject(localeEn.errors.wsConnection);
      });
      ws.addEventListener("close", () => {
        console.log("WebSocket Connection closed.");
      });
    });
    return new Client(ws);
  }

  enableDebug: boolean;

  private constructor(readonly ws: WebSocket) {
    this.enableDebug = Boolean(window.localStorage.getItem("DEBUG_MODE"));
    if (this.enableDebug) {
      console.log(`DEBUG MODE IS ENABLED.`);
    }
  }

  async requestSession() {
    const action = "requestSession";
    return this.request<SessionRequest, SessionResponse>(action, {
      action,
    });
  }

  async joinSession(sessionID: string) {
    const action = "joinSession";
    return this.request<JoinRequest, JoinResponse>(action, {
      action,
      payload: {
        sessionID,
      },
    });
  }

  async waitJoined(): Promise<JoinEvent> {
    const eventName: JoinEvent["event"] = "joined";
    return this.waitEvent<JoinEvent>(eventName);
  }

  async requestUploadURL(sessionID: string, filename: string) {
    const action = "requestUploadURL";
    return this.request<RequestUploadURLRequest, RequestUploadURLResponse>(
      action,
      {
        action,
        payload: {
          sessionID,
          filename,
        },
      }
    );
  }

  async waitPartnerBeganUpload() {
    const eventName: PartnerBeganUploadEvent["event"] = "beganUpload";
    return this.waitEvent<PartnerBeganUploadEvent>(eventName);
  }

  async completeUplaod(sessionID: string) {
    const action = "completeUpload";
    return this.request<CompleteUploadRequest, CompleteUploadResponse>(action, {
      action,
      payload: {
        sessionID,
      },
    });
  }

  async waitCompleteUpload() {
    const eventName: CompleteUploadEvent["event"] = "completed";
    return this.waitEvent<CompleteUploadEvent>(eventName);
  }

  private async request<Req extends ActionBase, Res extends ActionBase>(
    name: string,
    message: Req
  ): Promise<Res> {
    return new Promise((resolve, reject) => {
      this.ws.send(JSON.stringify(message));
      const onMessage = (event: MessageEvent) => {
        try {
          const resp = JSON.parse(event.data) as ActionBase;
          if (resp.action !== message.action) {
            return;
          }
          if (this.enableDebug) {
            console.group(name);
            console.log("Req", message);
            console.log("Res", resp);
            console.groupEnd();
          }
          this.ws.removeEventListener("message", onMessage);
          resolve(resp as Res);
        } catch (e) {
          reject(e);
        }
      };
      try {
        this.ws.addEventListener("message", onMessage);
      } catch (e) {
        reject(e);
      }
    });
  }

  private async waitEvent<E extends EventBase>(eventName: string): Promise<E> {
    return new Promise((resolve, reject) => {
      const onMessage = (event: MessageEvent) => {
        try {
          const message = JSON.parse(event.data) as EventBase;
          if (message.event !== eventName) {
            return;
          }
          if (this.enableDebug) {
            console.group(eventName);
            console.log("Event", message);
            console.groupEnd();
          }
          this.ws.removeEventListener("message", onMessage);
          resolve(message as E);
        } catch (e) {
          reject(e);
        }
      };
      try {
        this.ws.addEventListener("message", onMessage);
      } catch (e) {
        reject(e);
      }
    });
  }
}
