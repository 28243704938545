import React, { FC } from "react";
import { TheQr } from "@the-/ui-qr";
import { useLocale } from "../../../hooks/LocaleHook";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  caption: {
    width: "100%",
    textAlign: "center",
    marginTop: "4px",
  },
  self: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    border: "12px solid #ddd",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    paddingTop: "64px",
    paddingBottom: "64px",
  },
  makimono: {
    position: "absolute",
    top: "-36px",
    left: "-24px",
    height: "48px",
    width: "calc(100% + 48px)",
    background: "linear-gradient(to bottom, #4d4d4d, #aaa)",
    borderRadius: "0.1em",
    boxShadow: "0 10px 10px 0 rgba(0, 0, 0, .2)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "12px",
      left: "-12px",
      height: "24px",
      width: "12px",
      background: "#222",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "12px",
      right: "-12px",
      height: "24px",
      width: "12px",
      background: "#222",
    },
  },
}));

export const QrCode: FC<{ url: string; expired: boolean }> = ({
  url,
  expired,
}) => {
  const l = useLocale();
  const classes = useStyles();
  if (!url) {
    return null;
  }
  return (
    <div className={classes.self}>
      <div className={classes.makimono} />
      <TheQr text={url} alt={l.alts.qr} />
      <Typography
        variant="caption"
        color={expired ? "error" : "textPrimary"}
        className={classes.caption}
      >
        {expired ? l.errors.expired : l.leads.qr}
      </Typography>
    </div>
  );
};
