import { Client } from "../Client";
import { useAsync } from "./internals/AsyncHook";
import { useCallback, useState } from "react";
import { useMountEffect } from "./internals/MountEffectHook";

const CONNECTION_LIMIT_MS = 10 * 60 * 1000; // 10 minites

class ClientContainer {
  client: Client | null = null;
  needsConnect = true;

  async connectIfNeeded() {
    if (this.needsConnect) {
      this.needsConnect = false;
      const client = await Client.connect();
      this.client = client;
    }
  }
}

const singleton = new ClientContainer();
const options = { immediate: true };

type ClientHookResult = {
  busy: boolean;
  ready: boolean;
  client: Client | null;
  error: Error | null;
  expired: boolean;
};

export const useClient = (): ClientHookResult => {
  const connectClient = useCallback(() => singleton.connectIfNeeded(), []);
  const [expired, setExpired] = useState(false);
  useMountEffect(() => {
    // expire after CONNECTION_LIMIT_MS
    setTimeout(() => {
      singleton.client?.ws.close();
      setExpired(true);
    }, CONNECTION_LIMIT_MS);
  });
  const { busy, ready, error } = useAsync(connectClient, options);
  const { client } = singleton;
  return { busy, ready, client, error, expired };
};
