import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale } from "../../../hooks/LocaleHook";

const FILE_INPUT_ID = "send-button-file";

const useStyles = makeStyles(() => ({
  fileInput: {
    display: "none",
  },
  button: {
    padding: "12px 40px",
    fontSize: "1.5rem",
  },
}));

export const SendButton: FC<{
  onSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ onSelectFile }) => {
  const l = useLocale();
  const classes = useStyles();
  return (
    <>
      <input
        className={classes.fileInput}
        id={FILE_INPUT_ID}
        type="file"
        onChange={onSelectFile}
      />
      <label htmlFor={FILE_INPUT_ID}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component="span"
          endIcon={<SendIcon />}
        >
          {l.buttons.send}
        </Button>
      </label>
    </>
  );
};
