import React from "react";
import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import deepPurple from "@material-ui/core/colors/deepPurple";
import deepOrange from "@material-ui/core/colors/deepOrange";
import CssBaseline from "@material-ui/core/CssBaseline";
import { HashRouter, Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Layout } from "./layouts/Layout";
import { HostPage } from "./pages/host/HostPage";
import { GuestPage } from "./pages/guest/GuestPage";
import { NotFoundPage } from "./pages/error/NotFoundPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: deepPurple[800],
    },
    secondary: {
      main: deepOrange[900],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Layout>
          <ErrorBoundary>
            <Switch>
              <Route path="/" exact>
                <HostPage />
              </Route>
              <Route path="/sess/:sessionID">
                <GuestPage />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </ErrorBoundary>
        </Layout>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
